(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/bets-context/assets/javascripts/bets-context.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/bets-context/assets/javascripts/bets-context.js');
"use strict";


const {
  createContext
} = React;
const BetsContext = createContext({
  numPendingBets: 0,
  refreshPendingBets: () => {},
  refreshPendingBetsThrottled: () => {},
  setPendingBets: () => {}
});
setGlobal('svs.oddset_kambi.components.bets_context.BetsContext', BetsContext);

 })(window);