(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/bets-context/assets/javascripts/bets-context-provider.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/bets-context/assets/javascripts/bets-context-provider.js');
"use strict";


const {
  useState,
  useEffect,
  useMemo,
  useCallback
} = React;
const {
  getPendingBets
} = svs.oddset_kambi.components.wapiIntegration;
const {
  BetsContext
} = svs.oddset_kambi.components.bets_context;
const throttle = (func, delay) => {
  let timerFlag = null; 

  return function () {
    if (timerFlag === null) {
      func(...arguments);
      timerFlag = setTimeout(() => {
        timerFlag = null;
      }, delay);
    }
  };
};
const BetsContextProvider = _ref => {
  let {
    children
  } = _ref;
  const [numPendingBets, setNumPendingBets] = useState(0);
  const refreshPendingBets = useCallback(() => {
    getPendingBets();
  }, []);
  useEffect(() => {
    refreshPendingBets();
  }, [refreshPendingBets]);
  const value = useMemo(() => ({
    numPendingBets,
    refreshPendingBets,
    refreshPendingBetsThrottled: throttle(refreshPendingBets, 10000),
    setNumPendingBets
  }), [numPendingBets, refreshPendingBets, setNumPendingBets]);
  return React.createElement(BetsContext.Provider, {
    value: value
  }, children);
};
setGlobal('svs.oddset_kambi.components.bets_context.BetsContextProvider', BetsContextProvider);

 })(window);